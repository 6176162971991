import * as reportService from '../services/report';
import apiUtils from '../helpers/api_utils';

const store = {
  namespaced: true,
  state: () => ({
    downloading: false,
    courseList: [],
    courseItemsCount: 0,
    courseView: {},
    studentList: [],
    studentItemsCount: 0,
    studentView: {},
  }),
  mutations: {
    setDownloading(state, value) {
      state.downloading = value;
    },
    setCourseList(state, list) {
      state.courseList = list;
    },
    setCourseItemsCount(state, count) {
      state.courseItemsCount = count;
    },
    setCourseView(state, view) {
      state.courseView = view;
    },
    setStudentList(state, list) {
      state.studentList = list;
    },
    setStudentItemsCount(state, count) {
      state.studentItemsCount = count;
    },
    setStudentView(state, view) {
      state.studentView = view;
    },
  },
  actions: {
    // Courses actions: BEGIN
    async courseList(
      { state, commit },
      options = {
        clean: false,
      }
    ) {
      commit('system/loading', true, { root: true });
      const result = await reportService.courseList(options);

      state.courseList = result.list;
      state.courseItemsCount = result.paging.count;
      commit('system/loading', false, { root: true });
    },
    async courseView({ state, commit }, id) {
      state.courseView = {};
      commit('system/loading', true, { root: true });
      const result = await reportService.courseView(id);
      state.courseView = result;
      commit('system/loading', false, { root: true });
    },
    async downloadCourse({ state, commit }, options) {
      const result = await reportService.downloadCourse(options);
      return result;
    },
    // END

    // Students actions: BEGIN
    async studentList(
      { state, commit },
      options = {
        clean: false,
      }
    ) {
      commit('system/loading', true, { root: true });
      const result = await reportService.studentList(options);

      state.studentList = result.list;
      state.studentItemsCount = result.paging.count;
      commit('system/loading', false, { root: true });
    },
    async studentData({ state, commit }, id) {
      state.studentView = {};
      commit('system/loading', true, { root: true });
      const result = await reportService.studentView(id);
      state.studentView = result;
      commit('system/loading', false, { root: true });
    },
    async downloadStudent({ rootGetters, commit }, filters) {
      commit('setDownloading', true);
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(
        'report/student-download',
        filters,
        token,
        {
          responseType: 'blob',
        }
      );
      commit('setDownloading', false);
      return data;
    },
  },
};

export default store;
