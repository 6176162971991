export default {
  alerts: {
    general: {
      buttons: {
        cancel: 'cancelar',
        accept: 'sí',
      },
      successCreate: 'Creado con éxito!',
      errorCreate: 'Algún campo se completó incorrectamente',
      successEdit: 'Editado con éxito!',
      errorEdit: 'Error al Modificar',
      answerSelectError: 'selecciona la respuesta correcta',
      verifyAnswers: 'Crea al menos 2 respuestas',
      verifyLanguages: 'Check fields for another languages',
      leastOneQuestion: 'You need to create at least one question',
      errorToDownload: 'Error to download',
      loginExpired: 'Sesión expirada',
      pendindUsers: 'usuario(s) en espera de aprobación',
    },
    login: {
      errorPassword: 'Correo electrónico no válido/ contraseña incorrecta!',
      errorRecovery: 'Correo electrônico inválido',
      errorCode: 'Código inválido',
    },
    courses: {
      llSaveOrder: 'El pedido se guardará en instantes!',
      successSavedOrder: 'Pedido guardado!',
      remove: {
        title: 'Eliminar',
        content: 'Quieres eliminar este curso?',
      },
      removeModule: {
        title: 'Eliminar',
        content: 'Quieres eliminar este módulo?',
      },
      youNeedWait:
        'Necesitas esperar {count} {label} para volver a intentarlo.',
      minToPass:
        'Para recibir el certificado es necesario aprobar {min}% del examen.',
      manyTries: 'Muchos intentos, póngase en contacto con su master.',
      beforeExam:
        'Debe completar todos los módulos anteriores para realizar este examen.',
      removeQuestion: {
        title: 'Advertencia',
        content: 'Estas seguro?',
      },
      removeAnswer: {
        title: 'Advertencia',
        content: 'Estas seguro?',
      },
      removeMaterial: {
        title: 'Advertencia',
        content: 'Estas seguro?',
      },
    },
    users: {
      remove: {
        title: 'Eliminar',
        content: 'Quieres eliminar este usuario?',
      },
      notFound: 'User not found',
      errorSaved: 'Por favor revisa tu formulario antes de guardar',
    },
    teams: {
      remove: {
        title: 'Eliminar',
        content: 'Quieres eliminar este time?',
      },
      notFound: 'Team not found',
    },
    learningPaths: {
      remove: {
        title: 'Eliminar',
        content: 'Quieres eliminar esta trilha de aprendizado?',
      },
    },
    profile: {
      languageUpdade: {
        success: 'Cambio de idioma del sistema con éxito',
      },
      resetPassword: {
        title: 'Advertencia',
        content: 'Quieres restablecer tu contraseña?',
      },
    },
    report: {
      noDataToExport: 'No hay datos para exportar',
    },
    settings: {
      globalLabel: {
        title: 'Advertencia',
        content: '¿Agrega una etiqueta global a todos los usuarios y cursos?',
        success: {
          title: 'Éxito',
          content: 'Las etiquetas globales se agregarán con éxito.',
        },
      },
      initSettings: {
        title: 'Advertencia',
        content:
          '¿Desea iniciar la base de datos con la configuración predeterminada?',
        success: {
          title: 'Éxito',
          content: 'Las definiciones se actualizarán con éxito.',
        },
      },
      clearDBCache: {
        title: 'Advertencia',
        content: '¿Quieres borrar la caché de la base de datos?',
        success: {
          title: 'Éxito',
          content: 'La caché de la base de datos se borrará con éxito.',
        },
      },
      fixInvalidDate: {
        title: 'Advertencia',
        content:
          '¿Quieres arreglar fechas no válidas para usuarios que han terminado cursos?',
        success: {
          title: 'Éxito',
          content: 'Las fechas no válidas se corregirán en la base de datos.',
        },
      },
      master: {
        removeDistributor: {
          title: 'Advertencia',
          content:
            '¿Quieres eliminar este distribuidor? Al eliminar el distribuidor, todos los usuarios vinculados quedarán deshabilitados.',
        },
      },
    },
  },
  labels: {
    accept: 'Aceptado',
    actions: 'Acciones',
    addAnswer: 'Agregar respuesta',
    addLesson: 'Agregar lección',
    addMaterial: 'Agregar material',
    addModule: 'Agregar módulo',
    addQuestion: 'agregar pregunta',
    addDistribuitor: 'Agregar distribuidor',
    admin: 'Admin',
    all: 'All',
    allCourses: 'Todos los Cursos',
    answer: 'Respuesta',
    answerList: 'Lista de respuestas:',
    answerListEmpty: 'Lista de respuestas vacía',
    attempts: 'Attempts',
    approved: 'Aprobado',
    back: 'volver',
    backToCourse: 'volver al curso',
    backToCourses: 'volver a los cursos',
    backToLearningPaths: 'voltar para trilhas de aprendizado',
    backToTeam: 'volver al equipo',
    backToTeams: 'volver a los equipos',
    backToUsers: 'volver a los usuarios',
    cancel: 'cancelar',
    certificateLayout: 'Plantilla de Certificado',
    certificateGenerator: 'Generador de certificados',
    change: 'Alterar',
    championSince: 'Campeón Desde',
    city: 'ciudad',
    city: 'Ciudad',
    close: 'Close',
    country: 'pais',
    country: 'País',
    courses: 'Cursos',
    create: 'crear',
    createAccount: 'Crear una cuenta',
    createCourse: 'crear curso',
    createdAt: 'Creado en',
    createLearningPaths: 'criar trilha de aprendizado',
    createModule: 'Crear módulo',
    createTeam: 'Crear un equipo',
    createUser: 'Usuario creado',
    customDate: 'Fecha personalizada',
    dashboard: 'panel de control',
    description: 'descripción',
    dialCode: 'Codigo Internacional de Telefono',
    disabled: 'Discapacitado',
    disableds: 'Discapacitados',
    distributor: 'Distribuidor',
    done: 'Concluido',
    downloadCertificate: 'Descargar certificado',
    fact: 'FACT',
    edit: 'Modificar',
    editCourse: 'Modificar curso',
    editLesson: 'Modificar lección',
    editedWithSuccess: 'Editado con éxito!',
    editLearningPaths: 'Modificar trilha de aprendizado',
    editModule: 'Modificar módulo',
    editTeam: 'Modificar un equipo',
    editUser: 'Modificar usuario',
    email: 'correo electrónico',
    email: 'Email',
    en: 'English',
    enabled: 'Activado',
    enableds: 'Activados',
    endCourse: 'Terminar curso',
    enrollmentDate: 'Fecha de inscripción',
    errorLoadAnswers: 'Error to load answers',
    es: 'español',
    exam: 'examen',
    examAnswers: 'Exam Answers',
    examAttempts: 'Exam Attempts',
    exportXLS: 'Export XLS',
    file: 'File',
    fileWillBeRemove: 'El archivo se eliminará al aplicar',
    filter: 'Filtro',
    filterAdmins: 'Admins',
    filterLocale: 'Idioma',
    filterMasters: 'Masters',
    filterStatus: 'Status',
    filterStudents: 'Estudiantes',
    filterTSM: 'TSMs',
    flukeAcademyID: 'Fluke Academy ID',
    forgot: 'Olvido su contraseña?',
    generateCertificate: 'Generar certificado después del examen',
    generateCertificateAtTheEnd: 'Generar certificado al finalizar el curso',
    image: 'Image',
    insufficientScore: 'Puntaje insuficiente',
    isOptionalModule: 'Módulo opcional',
    hours: 'Horas',
    language: 'Idioma',
    languages: 'Idiomas',
    lastAttempt: 'Ultimo intento',
    lessons: 'leccíones',
    learningPaths: 'trilhas de aprendizado',
    learningPath: 'trilha de aprendizado',
    LMSInfo: 'Información LMS',
    loading: 'Cargando...',
    locationData: 'Ubicación',
    loginAsUser: 'Inicia sesión como este usuario',
    login: 'Acceso',
    logout: 'Salir',
    master: 'Master',
    master: 'Master',
    masters: 'Masters',
    materials: 'Materiales',
    masterDistributor: 'Master / Distribuidor',
    materialName: 'Material name',
    maxAttempsNumbers:
      'Ha agotado los {attemptsNumber} intentos disponibles para este prueba.',
    minimumGrade: 'Nota mínima',
    moduleDescription: 'Descripción del módulo',
    moduleMarkAsRead: 'Marcar módulo como visto',
    moduleWasRead: 'Concluido',
    myProgress: 'Mi Progreso',
    myCertificates: 'Mis Certificados',
    name: 'nombre',
    new: 'Nuevo',
    newCourse: 'Nuevo curso',
    newLearningPath: 'nueva trilha de aprendizado',
    newTeam: 'Nuevo equipo',
    next: 'siguiente',
    nextLesson: 'Leccione siguiente',
    noAttempt: 'No tienes más intentos',
    noLearningPaths: 'No tienes caminos de aprendizaje',
    noTeams: 'Todavia no haces parte de un equipo',
    notifications: 'notificaciones',
    others: 'otros',
    pending: 'Pendientes',
    password: 'contraseña',
    pdf: 'PDF',
    pdf: 'PDF',
    personalData: 'Información Personal',
    personalInfo: 'Información personal',
    phone: 'teléfono',
    phone: 'Telefono',
    post: 'Text',
    privacy: 'Política de privacidad',
    profile: 'Perfil',
    profilePicture: 'Foto de Perfil',
    progress: 'En progreso',
    pt: 'português',
    question: 'Prueba',
    questionList: 'Prueba:',
    questionListEmpty: 'lista de preguntas vacía',
    remainig: 'restantes',
    remove: 'Remove',
    region: 'Región',
    rejected: 'Rechazado',
    rejecteds: 'Rechazados',
    reports: 'reportes',
    requireCompletedModulesBeforeExam:
      'Requiere lecciones completadas antes del examen',
    reset: 'Reset',
    recovery: 'Recuperar contraseña',
    resetPassword: 'Alterar la contraseña',
    resetPassword: 'Alterar la contraseña',
    result: 'Resultado',
    restartExam: 'Volver a tomar el examen',
    startExam: 'Empezar examen',
    save: 'guardar',
    saveAnswer: 'guardar respuesta',
    saveQuestion: 'SALVAR PREGUNTA',
    search: 'Buscar',
    seeAnswers: 'Ver plantilla',
    selectAll: 'seleccionar todos',
    send: 'Enviar',
    settings: 'ajustes',
    state: 'Estado',
    states: 'estados',
    status: {
      disabled: 'Desactivado',
      done: 'Hecho',
      draft: 'Borrador',
      enabled: 'Activado',
      in_progress: 'En curso',
      pending: 'Pendiente',
      published: 'Publicado',
      rejected: 'Rechazado',
      undefined: 'Indefinida',
    },
    student: 'Estudiante',
    students: 'Estudiantes',
    structure: 'Estructura del curso',
    supportMaterial: 'Support material',
    teamLeader: 'Líder | Líderes',
    teamMembers: 'Miembros del equipo',
    teamMembersCount: 'estudiantes | estudiante',
    teamName: 'Nombre del equipo',
    team: 'equipo',
    teams: 'Equipos',
    title: 'título',
    trainingType: 'Tipo de entrenamiento',
    trainingTypes: 'Tipo de entrenamientos',
    trainingCategory: 'Categorías de formación',
    try: 'Intento',
    tryAgain: 'Intentar otra vez',
    tsm: 'TSM',
    TSMCategory: 'Categoría TSM',
    TSMCountry: 'País TSM',
    TSMInfo: 'Información TSM',
    TSMRegion: 'Regíon TSM',
    type: 'tipo',
    userCategory: 'Categoria de usuario',
    userClassification: 'Clasificación de usuario',
    users: 'usuarios',
    userStatus: 'Estatus del usuario',
    userType: 'Tipo de usuario',
    value: 'valor',
    video: 'video',
    vimeoID: 'Vimeo video ID',
    welcomeCreatePass:
      '¡Bienvenido a la nueva plataforma! Como es su primer acceso, le enviamos un link a su correo electrónico para que cree una nueva contraseña.',
    youNeddChooseTheRightAnswer: 'Elige la respuesta correcta',
    youNeddTwoQuestion: 'Se requiere un mínimo de 2 preguntas!',
    youNeedOneQuestion: 'Mínimo de una pregunta!',
  },
  rules: {
    allLangsField: 'Es necesario rellenar en todos los idiomas',
    required: 'Obligatório',
  },
  texts: {
    dash: {
      dev: 'en desarrollo',
    },
  },
  settings: {
    names: {
      functions: 'funciones',
      customCertificate: 'crear certificado',
      addGlobalLabel: 'añadir etiqueta global',
      initGlobalDef: 'Definiciones globales de inicio',
      clearDBCache: 'borrar caché de base de datos',
      data: 'datos',
      config: 'configuración',
      time_wait_exam_tries:
        'Tiempo de espera entre intentos de examen (en horas)',
      max_exam_tries: 'Máximo de intentos de examen',
      sendgrid_api_key: 'Clave API Sendgrid',
      mail_from: 'Desde el correo electrónico',
      site_url: 'URL del sitio',
      api_url: 'URL API',
      trainingType: 'tipo de entrenamiento',
      userCategory: 'categorías de usuarios',
      countryDist: 'países/distribuidores',
      fixInvalidDate: 'Corregir fecha inválida de cursos terminados',
      checkusers: 'Verificar Usuarios',
    },
  },
  loginPage: {
    accept: {
      text: 'Acepto el ',
      link: 'política de privacidad',
    },
    email: 'Correo electrónico',
    back: 'volver',
    country: 'país',
    state: 'estado',
    name: 'Nombre Completo',
    password: 'Contraseña',
    passwordConfirmation: 'Confirmación de contraseña',
    country: 'país',
    master: 'Master',
    langs: {
      portuguese: 'Português',
      english: 'English',
      spanish: 'Español',
    },
    pages: {
      recovery: {
        title: 'Recuperar contraseña',
        disclaimer:
          'Ingresa tu correo electrónico a continuación y te enviaremos instrucciones para restablecer tu contraseña.',
      },
      verifyCode: {
        title: 'Verificar código',
        disclaimer: 'Ingrese el código que se envió a su correo electrónico',
      },
      forgot: {
        title: 'Olvido su contraseña?',
        text: 'Ingrese su correo electrónico para recibir un link y crear una nueva contraseña',
        invalidEmail: 'Correo electrónico inválido',
        success:
          'Link enviado a su correo electrónico! Verifique en unos segundos',
        error: 'Cuenta no encontrada',
      },
      reset: {
        title: 'Alterar su contraseña',
        text: 'Crear nueva contraseña',
        newPass: 'Nueva contraseña',
        confirmPass: 'Confirmación de contraseña',
        invalidPassword: 'Mínimo 6 caracteres',
        invalidConfirmation: 'contraseña invalida!',
        goToLogin: 'Ir a la pagina de acceso',
        success: 'Contraseña modificada!',
        error: 'Link inválido',
      },
      register: {
        title: 'Crear una cuenta',
        text: 'Crear su cuenta',
        success:
          'Registro enviado con éxito! Procesaremos el registro y en instantes recibirá su acceso.',
        error: 'No se pudo registrar, inténtelo nuevamente',
        errorDuplicated:
          'No se pudo registrar, este correo electrónico ya está registrado!',
        goToLogin: 'Ir para la pagina de acceso',
        invalids: {
          name: 'Nombre inválido',
          email: 'Correo electrónico Inválido',
          password: 'Contraseña inválida',
          country: 'País invalido',
          state: 'Estado inválido',
          master: 'Master inválido',
          passwordConfirmation: 'Confirmación de contraseña inválida',
          passwordConfirmationDifferent: 'Las contraseñas no coinciden',
        },
      },
    },
  },
  reports: {
    back: 'volver a los informes',
    course: {
      course: 'Curso',
      studentsCount: 'Total estudiantes',
      studentsProgress: 'Estudiantes haciendo',
      studentsDone: 'Estudiantes terminados',
      name: 'Nombre',
      progress: 'Progreso',
      done: 'Hecho',
      totalStudents: 'Total de estudiantes',
      doneStudents: 'Hecho',
      doneDate: 'Fecha de finalización',
    },
    student: {
      name: 'Nombre',
      coursesCount: 'Cursos haciendo',
      coursesDone: 'Cursos realizados',
      totalCourses: 'Cursos totales',
      doneCourses: 'Hecho',
      course: 'Curso',
      progress: 'Progreso',
      done: 'Hecho',
      doneDate: 'Fecha de finalización',
      certificateError: 'Error al generar certificado',
    },
  },
  notifications: {
    clear: 'Notificaciones claras',
    default: 'Todo está claro',
    course: {
      add: 'Se agregó un nuevo curso',
      update: 'Se actualizó un curso',
    },
    linearLesson: {
      add: 'Se agregó una nueva leccíon',
      update: 'Se actualizó una leccíon',
    },
    module: {
      add: 'Se agregó un nuevo modulo',
      update: 'Se actualizó un modulo',
    },
    moduleLesson: {
      add: 'Se agregó una nueva leccion ',
      update: 'Se actualizó una leccion',
    },
    team: {
      add: 'Has sido agregado a un equipo',
      update: 'Se ha modificado un equipo del que formas parte.',
    },
    learningPath: {
      add: 'Has sido agregado a una trilha de aprendizado',
      update: 'Se ha modificado una trilha de aprendizado del que formas parte',
    },
  },
  rating: {
    title: 'Calificación General',
    subtitle: 'Calificaciones',
  },
  users: {
    edit: {
      alertMissing:
        'A tu perfil le faltan algunos datos importantes, complétalos!',
    },
    view: {
      filterCourses: 'Cursos',
      filterTeams: 'Equipos',
      filterActivities: 'Actividades',
      filterConquests: 'Conquistas',
      statusLabel: 'Status',
      team: 'Team',
      leader: 'Leader',
      yes: 'Sí',
      activity: 'Actividad',
      createdAt: 'Fecha',
      activityType: 'El tipo',
      activiteTypes: {
        course: 'Conquista',
        module: 'Módulo',
        user: 'Personas',
      },
      activities: {
        course:
          '<strong>{name}</strong> ganó un nuevo logro <strong>{course}</strong>',
        module:
          '<strong>{name}</strong> completó el módulo <strong>{module}</strong>',
        user: 'La cuenta de <strong>{name}</strong> fue actualizado por <strong>{updatedBy}</strong>',
      },
    },
  },
  apiStatus: {
    7: 'Este correo electrónico ya está registrado en otro país',
  },
  profile: {
    options: {
      personalData: 'Información Personal',
      language: 'Idioma',
      resetPassword: 'Restablecer la Contraseña',
      logout: 'Salir',
    },
  },
};
